import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import LogoWhite from "../images/Logo.svg"
import LogoBlack from "../images/LogoBlack.svg"
import { navigate } from "gatsby"
import { ListItem, ListItemText } from "@material-ui/core"

const drawerWidth = 240

export default function MobileHeader({ color }) {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
    },
    appBar: {
      boxShadow: "none",
      backgroundColor:
        color === "white" ? "rgba(0, 0, 0, .7)" : "rgba(255, 255, 255, .7)",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
    },
    burgerIcon: {
      color: color,
    },
  }))
  const classes = useStyles()
  const [isOpen, setIsOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setIsOpen(true)
  }

  const handleDrawerClose = () => {
    setIsOpen(false)
  }

  return (
    <div className={classes.root}>
      <AppBar
        color="transparent"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
      >
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
            <img
              style={{
                position: "relative",
                left: isOpen ? "0" : "24px",
                maxHeight: "55px",
                marginTop: "1rem",
                marginBottom: ".30rem",
              }}
              src={color === "black" ? LogoBlack : LogoWhite}
              alt="logo"
            />
          </Typography>

          <IconButton
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(classes.burgerIcon, isOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
          <Typography>Flying Flo</Typography>
        </div>
        <Divider />
        <List>
          {[
            { text: "HOME", to: "/" },
            { text: "ABOUT", to: "/about" },
            { text: "SERVICES", to: "/services" },
            { text: "GALLERY", to: "/gallery" },
            { text: "TRAVEL WITH ME", to: "/travel" },
            { text: "CONTACT", to: "/contact" },
          ].map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                navigate(item.to)
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  )
}
