import React from "react"
import IconButton from "@material-ui/core/IconButton"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import YouTubeIcon from "@material-ui/icons/YouTube"

const SocialButtons = () => {
  return (
    <div
      style={{
        position: "fixed",
        left: "0",
        bottom: "30%",
        display: "flex",
        flexDirection: "column",
        zIndex: "1001",
        borderRadius: "0 25px 25px 0",
        backgroundColor: "rgba(0, 0, 0, .6",
        padding: ".25rem .35rem .25rem .35rem",
      }}
    >
      <a href="https://www.facebook.com/flo.rochard" aria-label="Facebook">
        <IconButton
          size="small"
          style={{
            backgroundColor: "white",
            color: "black",
            marginBottom: "1rem",
          }}
          children={<FacebookIcon fontSize="large" />}
        />
      </a>
      <a
        href="https://instagram.com/flo.rochard?igshid=cxwl9ii3ol8w"
        aria-label="Instagram"
      >
        <IconButton
          size="small"
          style={{
            backgroundColor: "white",
            color: "black",
            marginBottom: "1rem",
          }}
          children={<InstagramIcon fontSize="large" />}
        />
      </a>
      <a
        href="https://www.youtube.com/channel/UCLemPIFDG9eNWSlMQEoM08g"
        aria-label="YouTube"
      >
        <IconButton
          size="small"
          style={{ backgroundColor: "white", color: "black" }}
          children={<YouTubeIcon fontSize="large" />}
        />
      </a>
    </div>
  )
}

export default SocialButtons
