import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BgImage from "../bgImage"

const BgTravel = ({
  bgPosition = "center",
  customHeight = "50vh",
  children,
  id,
}) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Travel.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BgImage
      data={data}
      customHeight={customHeight}
      bgPosition={bgPosition}
      id={id}
    >
      {children}
    </BgImage>
  )
}

export default BgTravel
