import React from "react"
import BackgroundImage from "gatsby-background-image"

const BgImage = ({
  data,
  children,
  bgPosition = "center",
  customHeight = "100vh",
  positionFixed,
  id,
}) => {
  return (
    <BackgroundImage
      id={id}
      style={
        positionFixed
          ? {
              width: "100%",
              height: customHeight,
              backgroundSize: "cover",
              backgroundPosition: bgPosition,
              position: "fixed",
              top: "0",
              zIndex: "-1",
            }
          : {
              width: "100%",
              height: customHeight,
              backgroundSize: "cover",
              backgroundPosition: bgPosition,
            }
      }
      fluid={data.placeholderImage.childImageSharp.fluid}
    >
      {children}
    </BackgroundImage>
  )
}

export default BgImage
