import React, { useState } from "react"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { navigate } from "gatsby"
import DropdownMenu from "./dropdownMenu"
import { makeStyles } from "@material-ui/core/styles"

const NavBar = ({ color }) => {
  const [value, setValue] = useState(
    typeof window !== "undefined" ? window.location.pathname : ""
  )
  const [hoverChecker, setHoverChecker] = useState(0)
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState({
    "/services": false,
    "/gallery": false,
  })

  const useStyledTabs = makeStyles({
    root: {
      color: color,
    },
    indicator: {
      backgroundColor: color,
    },
    typography: {
      fontWeight: "bold",
      zIndex: "10000",
    },
  })
  const tabsClasses = useStyledTabs()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleMouseOver = () => setHoverChecker(hoverChecker + 1)

  return (
    <nav>
      <Tabs
        value={value}
        classes={{ root: tabsClasses.root, indicator: tabsClasses.indicator }}
        indicatorColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab
          label="Home"
          value="/"
          classes={{ root: tabsClasses.typography }}
          onClick={() => {
            navigate("/")
          }}
          onMouseOver={handleMouseOver}
        />
        <Tab
          label="About"
          value="/about"
          classes={{ root: tabsClasses.typography }}
          onClick={() => {
            navigate("/about")
          }}
          onMouseOver={handleMouseOver}
        />
        <DropdownMenu
          title="Services"
          value="/services"
          color={color}
          hoverChecker={hoverChecker}
          isDropdownMenuOpen={isDropdownMenuOpen}
          setIsDropdownMenuOpen={setIsDropdownMenuOpen}
        />
        <DropdownMenu
          title="Gallery"
          value="/gallery"
          color={color}
          hoverChecker={hoverChecker}
          isDropdownMenuOpen={isDropdownMenuOpen}
          setIsDropdownMenuOpen={setIsDropdownMenuOpen}
        />
        <Tab
          label="Travel With Me"
          value="/travel"
          classes={{ root: tabsClasses.typography }}
          onClick={() => {
            navigate("/travel")
          }}
          onMouseOver={handleMouseOver}
        />
        <Tab
          label="Contact"
          value="/contact"
          classes={{ root: tabsClasses.typography }}
          onClick={() => {
            navigate("/contact")
          }}
          onMouseOver={handleMouseOver}
        />
      </Tabs>
    </nav>
  )
}

export default NavBar
