import React, { Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import SpeedDial from "@material-ui/lab/SpeedDial"
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon"
import SpeedDialAction from "@material-ui/lab/SpeedDialAction"
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer"
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import YouTubeIcon from "@material-ui/icons/YouTube"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import { navigate } from "gatsby"
const useStyles = makeStyles(theme => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
    position: "fixed",
    bottom: "0",
    right: "0",
    zIndex: "50000",
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

const actions = [
  { icon: <MailOutlineIcon />, name: "Contact Me", to: "/contact" },
  {
    icon: <YouTubeIcon />,
    name: "YouTube",
    to: "https://www.youtube.com/channel/UCLemPIFDG9eNWSlMQEoM08g",
  },
  {
    icon: <InstagramIcon />,
    name: "Instagram",
    to: "https://instagram.com/flo.rochard?igshid=cxwl9ii3ol8w",
  },
  {
    icon: <FacebookIcon />,
    name: "Facebook",
    to: "https://www.facebook.com/flo.rochard",
  },
]

export default function OpenIconSpeedDial() {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = (event, target) => {
    console.log(target)
  }

  if (typeof window !== "undefined") {
    return (
      <div className={classes.root}>
        <SpeedDial
          ariaLabel="SpeedDial openIcon example"
          className={classes.speedDial}
          icon={
            <SpeedDialIcon
              icon={<QuestionAnswerOutlinedIcon />}
              openIcon={<QuestionAnswerIcon />}
            />
          }
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map(action =>
            action.to === "/contact" ? (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => navigate(action.to)}
              />
            ) : (
              <Fragment key={action.name}>
                <SpeedDialAction
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() =>
                    document.getElementById(action.to + "-link").click()
                  }
                />
                <a
                  id={action.to + "-link"}
                  href={action.to}
                  rel="noreferrer"
                  target="_blank"
                  style={{ display: "none" }}
                >
                  {action.to}
                </a>
              </Fragment>
            )
          )}
        </SpeedDial>
      </div>
    )
  } else return <></>
}
