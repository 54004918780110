import React, { useState, useEffect } from "react"
import { createMuiTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"

import Header from "./header"
import Footer from "./footer"
import Speeddial from "./speeddial"
import "./layout.css"
import BgAbout from "./bgImages/about"
import BgContact from "./bgImages/contact"
import BgTravel from "./bgImages/travel"
import BgHome from "./bgImages/home"
import SocialButtons from "./socialButtons"
import MobileHeader from "./mobileHeader"
const theme = createMuiTheme({
  typography: {
    fontFamily: ["Libre Baskerville"].join(","),
  },
})

const Layout = ({ contentOverImage, children }) => {
  const [headerColor, setHeaderColor] = useState("white")
  const [isMobile, setIsMobile] = useState()
  const userLang =
    typeof window !== "undefined" &&
    (navigator.language || navigator.userLanguage)
  console.log(userLang)

  const BgSwitch = ({ children }) => {
    const path = typeof window !== "undefined" && window.location.pathname
    switch (path) {
      case "/about":
        return <BgAbout children={children} />
      case "/contact":
        return <BgContact children={children} />
      case "/gallery":
        setHeaderColor("black")
        return (
          <div
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              marginBottom: "2rem",
            }}
          >
            {children}
          </div>
        )
      case "/services":
        setHeaderColor("black")
        return (
          <div
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              marginBottom: "2rem",
            }}
          >
            {children}
          </div>
        )
      case "/travel":
        return <BgTravel children={children} />
      case "/conditions":
        return (
          <div style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}>{children}</div>
        )
      default:
        return <BgHome children={children} />
    }
  }

  const HeaderSwitch = ({ color }) => {
    if (isMobile) {
      return <MobileHeader color={color} />
    } else {
      return <Header color={color} />
    }
  }
  useEffect(() => {
    const viewWidth = typeof window !== "undefined" && window.innerWidth
    if (viewWidth > 900) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <div style={{ position: "relative", height: "100vh" }}>
        {contentOverImage ? (
          <>
            <BgSwitch />
            <HeaderSwitch color={headerColor} />
          </>
        ) : (
          <BgSwitch>
            <HeaderSwitch color={headerColor} />
          </BgSwitch>
        )}
        <main style={{ marginTop: isMobile ? "100px" : "0" }}>{children}</main>
        {!isMobile && <SocialButtons />}
        <Speeddial />
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default Layout
