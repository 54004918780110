import React, { useEffect, useState } from "react"
import Tab from "@material-ui/core/Tab"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from "@material-ui/core/ListItemText"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyledMenu = makeStyles({
  paper: {
    backgroundColor: "rgba(0,0,0,.6)",
  },
})

export default function DropdownMenu({
  title,
  color,
  value,
  hoverChecker,
  isDropdownMenuOpen,
  setIsDropdownMenuOpen,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [hoverValue, setHoverValue] = useState()

  const menuClasses = useStyledMenu()

  const handleMouseOver = event => {
    setAnchorEl(event.currentTarget)
    setIsDropdownMenuOpen({ ...isDropdownMenuOpen, [value]: true })
  }

  const handleClose = () => {
    setAnchorEl(null)
    setIsDropdownMenuOpen({ ...isDropdownMenuOpen, [value]: false })
  }

  useEffect(() => {
    if (hoverChecker !== hoverValue) {
      setHoverValue(hoverChecker)
      handleClose()
    }
  }, [hoverChecker])

  useEffect(() => {
    if (anchorEl) {
      // eslint-disable-next-line default-case
      switch (value) {
        case "/gallery":
          isDropdownMenuOpen["/services"] &&
            setIsDropdownMenuOpen({ ...isDropdownMenuOpen, "/services": false })
          break
        case "/services":
          isDropdownMenuOpen["/gallery"] &&
            setIsDropdownMenuOpen({ ...isDropdownMenuOpen, "/gallery": false })
      }
      !isDropdownMenuOpen[value] && handleClose()
    }
  }, [isDropdownMenuOpen])

  const options = [
    {
      label: "Advertising",
      to: "/services#serviceAdvertising",
      target: "Services",
    },
    {
      label: "Wedding",
      to: "/services#serviceWedding",
      target: "Services",
    },
    {
      label: "Event",
      to: "/services#serviceEvent",
      target: "Services",
    },
    {
      label: "Advertising",
      to: "/gallery#galleryAdvertising",
      target: "Gallery",
    },
    {
      label: "Wedding",
      to: "/gallery#galleryWedding",
      target: "Gallery",
    },
    {
      label: "Event",
      to: "/gallery#galleryEvent",
      target: "Gallery",
    },
  ]

  return (
    <>
      <Tab
        aria-controls="fade-menu"
        aria-haspopup="true"
        onMouseOver={handleMouseOver}
        onClick={() => navigate(value)}
        style={{ color: color, fontWeight: "bold", zIndex: "10000" }}
        label={title}
      />
      <Menu
        classes={{ paper: menuClasses.paper }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        disableScrollLock
      >
        {/*eslint-disable-next-line*/
        options.map(option => {
          if (option.target === title) {
            return (
              <MenuItem
                style={{ color: "white" }}
                key={option.label}
                onClick={() => {
                  navigate(option.to)
                }}
              >
                <ListItemText primary={option.label} />
              </MenuItem>
            )
          }
        })}
      </Menu>
    </>
  )
}
