import React from "react"
import Typography from "@material-ui/core/Typography"

import NavBar from "./navbar"
import LogoWhite from "../images/Logo.svg"
import LogoBlack from "../images/LogoBlack.svg"

const Header = ({ color }) => {
  return (
    <header style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          height: "100%",
        }}
      >
        <img
          style={{
            maxHeight: "75px",
            marginTop: "1rem",
            marginBottom: ".75rem",
          }}
          src={color === "black" ? LogoBlack : LogoWhite}
          alt="logo"
        />
        <Typography
          style={{ color: color, fontSize: "1.75rem" }}
          align="center"
          component="h2"
        >
          Flying Flo
        </Typography>
        <NavBar color={color} />
      </div>
    </header>
  )
}

export default Header
