import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BgImage from "../bgImage"

const BgHome = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "homepage.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <BgImage data={data} positionFixed>
      {children}
    </BgImage>
  )
}

export default BgHome
