import React from "react"
import { Link } from "gatsby"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"

import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import YouTubeIcon from "@material-ui/icons/YouTube"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import MailIcon from "@material-ui/icons/Mail"

const Footer = () => {
  return (
    <footer
      style={{
        padding: " 1rem 0",
        backgroundColor: "rgba(0, 0, 0, .6)",
        color: "white",
      }}
    >
      <Grid container style={{ paddingLeft: "3rem" }}>
        <Grid item xs={12} sm={6}>
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={12} sm={12}>
              <p>SOCIAL NETWORK</p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <IconButton
                size="small"
                style={{
                  color: "white",
                }}
                children={<FacebookIcon fontSize="large" />}
              />
              <IconButton
                size="small"
                style={{
                  color: "white",
                }}
                children={<InstagramIcon fontSize="large" />}
              />
              <IconButton
                size="small"
                style={{ color: "white" }}
                children={<YouTubeIcon fontSize="large" />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container>
            <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
              <p>CONTACT</p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container justify="center">
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    <Grid item xs={1} sm={1}>
                      <BusinessCenterIcon style={{ color: "white" }} />
                    </Grid>
                    <Grid item xs={11} sm={11}>
                      <p>Flying Flo Video Services</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid container>
                    <Grid item xs={1} alignItems="flex-end">
                      <MailIcon style={{ color: "white" }} />
                    </Grid>
                    <Grid item xs={11}>
                      <a
                        href="mailto:contact@flyingflo.com"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        contact@flyingflo.com
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
          <Link
            to="/conditions"
            style={{
              color: "white",
              textDecoration: "none",
              fontSize: "0.8rem",
            }}
          >
            Terms & Conditions
          </Link>
        </Grid>
        <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
          © {new Date().getFullYear()}, Made with 🍉 by{" "}
          <a href="#" style={{ color: "white" }}>
            Amandine and Mat
          </a>
        </Grid>
        <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
          <p style={{ fontSize: "0.8rem" }}>
            Copyright Flying Flo Video Services All Rights Reserved ©{" "}
            {new Date().getFullYear()}
          </p>
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer
